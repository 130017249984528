<template>
    <div class="dynamic">
        <div class="banner">
            <div class="banner-container">
                <div>
                    <h2>關於我們</h2>
                    <h4></h4>
                    <!--                    <p>重庆天行健科技有限公司</p>-->
                </div>
                <img src="../assets/img/app development.png" alt="#">
            </div>
        </div>
        <div class="content">
            <div class="content-container">
                <div>
                    <h2><span></span>我們的服務</h2>
                    <p>
                        公司成立於2023年03月，經營包括網絡技術服務，信息技術諮詢服務，廣告設計、代理，廣告發布（非廣播電台、電視台、報刊出版單位），廣告製作（除依法須經批准的項目外，憑營業執照依法自主開展經營活動）等互聯網技術和廣告相關服務；
                    </p>
                    <p>
                        平台主要服務於國內的初創互聯網產品，助其推廣、擴張及規模化成型。媒體服務主要依託於增值運營，深挖生態閉環資源，能夠大幅有效的提升資源價值；
                    </p>
                    <p>
                        平台海外服務可以為國際化產品提供標準化Google等資源對接，幫助其投放、運營及規模化，同時非標準化資源可以根據產品或媒體提供專項服務；
                    </p>
                </div>
                <div>
                    <h2><span></span>我們的資源優勢</h2>
                    <p>
                        世創平台擁有國內外主流Ad network、Ad
                        Exchange、DSP平台20餘家，對接數萬級的廣告主資源，覆蓋37個行業（品牌、效果、App推廣等），可以實現每一個流量充分競價，全面提升媒體的變現能力和收益；
                    </p>
                    <p>
                        自研世創廣告小程序服務插件完全支持騰訊官方小程序廣告接入，該服務系統由騰訊雲支持，流量安全性強，支持小程序流量植入、整形，流量真實性高，部分服務內容支持h5廣告落地頁或小程序原生頁面落地頁廣告推廣及產品接入；
                    </p>
                    <p>
                        整合了國內95%以上的一線sdk服務，實現資源互補，併為不同產品提供不同的對接方案幫助其獲取穩定的廣告預算資源。自有運營體系能夠很好的實現流量的價值提升；
                    </p>
                </div>
                <div>
                    <h2><span></span>我們的團隊分工及技術支持</h2>
                    <p>
                        平台擁有專業的技術研發、廣告運營和廣告投放團隊，提供真正的全流程服務，增值價值普遍高於市場20%-30%；
                    </p>
                    <p>
                        平台海外服務可以為國際化產品提供標準化Google等資源對接，幫助其投放、運營及規模化，同時非標準化資源可以根據產品或媒體提供專項服務；
                    </p>
                </div>
                <div class="posi-absolute">

                </div>
                <!-- <img src="../assets/img/webg.webp" alt=""> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {}
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');

    .dynamic {
        .banner {
            background: url('../assets/img/type-banner-bg.png');
            background-size: contain;

            .banner-container {
                width: 1200px;
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
                height: 430px;
                overflow: hidden;

                img:nth-of-type(1) {
                    position: absolute;
                    top: 30px;
                    right: 0px;
                    width: 490px;
                }

                > div {
                    h2 {
                        font-size: 40px;
                        font-weight: 600;
                    }

                    h4 {
                        background: @themeColor;
                        width: 32px;
                        height: 4px;
                        margin: 12px 0 24px;
                    }

                    p {
                        font-size: 18px;
                        width: 530px;
                    }
                }
            }
        }

        .content {
            padding: 60px 0 150px;
            background: #fefefe;

            .content-container {
                position: relative;
                padding: 2px 0 50px 50px;
                margin: 0 auto;
                width: 1200px;

                h2 {
                    font-size: 24px;
                    font-weight: 600;
                    margin: 60px 0 40px;
                    position: relative;

                    span {
                        position: absolute;
                        width: 30px;
                        height: 12px;
                        background: @themeColor;
                        top: 10px;
                        left: -58px;
                        border-bottom-right-radius: 6px;
                        border-top-right-radius: 6px;
                    }
                }

                p {
                    margin-top: 36px;
                }

                > div {
                    margin-bottom: 80px;
                }

                > img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    opacity: .1;
                    width: 800px;
                }
            }

            .posi-absolute {
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background: @themeColor;
            }

        }
    }
</style>